import { agreeCookies } from '../../../common/scripts/cookieLaw'

function buildProcessUrl(baseUrl, params) {
  const url = new URL(baseUrl)
  const searchParams = new URLSearchParams(window.location.search)
  const urlParams = new URLSearchParams({
    ...Object.fromEntries(url.searchParams),
    ...Object.fromEntries(searchParams),
    ...Object.fromEntries(params)
  });
  return `${url.origin}${url.pathname}?${urlParams}`
}

function setupProductCalculator(container) {
  const processUrl = container.dataset.url;
  let formFieldValues = new URLSearchParams();

  for(const key of Object.keys(container.dataset)) {
    if (key.indexOf('form') !== 0) continue;
    let field = key.replace(/^(form\.?)/, '')
    field = field[0].toLowerCase() + field.slice(1)

    formFieldValues.append('form.' + field, container.dataset[key]);
  }

  const submitButton = container.querySelector('.product-button')
  const submitLink = container.querySelector('#submitLink')
  const amountSlider = container.querySelector('.amount-slider');
  const periodSlider = container.querySelector('.period-slider');
  const isLink = submitButton.classList.contains('product-button-link');
  const isForm = container.classList.contains('container-form')

  if (!submitButton) {
    return;
  }

  let formFields = new Map()
  let formFieldIDs = [
    'firstName',
    'lastName',
    'email',
    'phoneMobile',
    'confirmTerms',
    'confirmCreditorTerms',
    'confirmDataProcessing',
    'confirmExternalMarketing',
    'confirmWhatsapp',
  ]

  if (isForm) {
    for (let i = 0; i < formFieldIDs.length; i++) {
      if (container.querySelector('#' + formFieldIDs[i])) {
        formFields.set('form.' + formFieldIDs[i], container.querySelector('#' + formFieldIDs[i]));
      }
    }

    addFieldListeners(formFields, submitButton, submitLink, formFieldValues)
    validateForm(formFields, submitButton, submitLink)
  }

  if (isLink) {
    submitButton.setAttribute('href', buildProcessUrl(processUrl, formFieldValues))
  }

  submitButton.addEventListener('click', function() {
    agreeCookies()

    if (isLink) {
      return
    }

    if (isForm) {
      const isValid = validateForm(formFields, submitButton, submitLink)
      if (!isValid) {
        return
      }
    }

    window.location.href = buildProcessUrl(processUrl, formFieldValues)
  })

  if (amountSlider) {
    amountSlider.addEventListener('fiInput', (e) => {
      formFieldValues.set('form.applicationPrincipal', e.detail)

      if (!isLink) {
        return;
      }

      submitButton.setAttribute('href', buildProcessUrl(processUrl, formFieldValues))
    })
  }

  if (periodSlider) {
    periodSlider.addEventListener('fiInput', (e) => {
      formFieldValues.set('form.applicationPeriod', e.detail)

      if (!isLink) {
        return;
      }

      submitButton.setAttribute('href', buildProcessUrl(processUrl, formFieldValues))
    })
  }
}

function addFieldListeners(fields, submitButton, submitLink, formFieldValues) {
  for (const [fieldKey, field] of fields) {
    field.addEventListener('fiInput', (e) => {
      let value = e.detail
      if (e.detail.unmasked) {
        value = e.detail.unmasked
      }

      const regex = field.getAttribute('validation-regex')
      if (regex) {
        if (validateValue(value, regex)) {
          field.setAttribute('validation-class', 'valid')
          formFieldValues.append(fieldKey, value)
        } else {
          field.setAttribute('validation-class', 'error')
        }
      } else {
        formFieldValues.append(fieldKey, value)
      }

      if (submitLink) {
        const searchParams = new URLSearchParams(submitLink.search)
        for (const [fieldKey, fieldValue] of formFieldValues) {
          searchParams.set(fieldKey, fieldValue)
        }
        submitLink.search = searchParams
      }

      validateForm(fields, submitButton, submitLink)
    })
  }
}

function validateForm(fields, submitButton, submitLink) {
  let validFields = 0

  for (const field of fields.values()) {
    const isValid = field.getAttribute('validation-class')
    const regex = field.getAttribute('validation-regex')


    if (regex === null && isValid === null) {
      validFields++
    }

    if (isValid === 'valid') {
      validFields++
    }
  }

  if (validFields === fields.size) {
    submitButton.setAttribute('disabled', 'false')

    if (submitLink) {
      submitLink.classList.remove('disabled-link')
    }

    return true
  } else {
    submitButton.setAttribute('disabled', 'true')

    if (submitLink) {
      submitLink.classList.add('disabled-link')
    }
  }

  return false
}

function validateValue(value, regex) {
  return new RegExp(regex).test(value)
}

document.addEventListener('DOMContentLoaded', () => {
  const calculators = document.getElementsByClassName('calculator')

  if (calculators.length == 0) {
    return
  }

  for (let e of calculators) {
    setupProductCalculator(e)
  }
})
