import Glide from '@glidejs/glide'

const SlideCounterExtension = function(Glide, Components, Events) {
  function updateTotalSlides() {
    const total = Glide.selector.querySelector('[data-glide-el="total-slides"]')
    if (total) {
      total.innerHTML = Math.ceil(Components.Sizes.length / Glide.settings.perView);
    }
  }

  function updateCurrentSlide() {
    const current = Glide.selector.querySelector('[data-glide-el="current-slide"]')
    if (!current) {
      return;
    }

    if (Glide.settings.perSwipe === '|') {
      // in case of bound, the last slide index could be off, need to account for that
      if (Glide.settings.bound && (Components.Sizes.length % Glide.settings.perView) !== 0 && Glide.index % Glide.settings.perView !== 0 ) {
        current.innerHTML = Math.ceil(Components.Sizes.length / Glide.settings.perView);
      } else {
        current.innerHTML = Math.ceil((Glide.index + 1) / Glide.settings.perView);
      }
    } else {
      current.innerHTML = (Glide.index + 1);
    }
  }

  function updateControls() {
    // hide controls if we have only one page
    const controls = Glide.selector.querySelector('[data-glide-el="controls"]');

    if (controls) {
      if (Math.ceil(Components.Sizes.length / Glide.settings.perView) == 1) {
        controls.style.display = 'none';
      } else {
        controls.style.removeProperty('display');
      }
    }
  }

  // fix glide autoplay, it always slides by one slide, but sometimes we want to slide per viewport.
  Events.on('run.before', evt => {
    if (Glide.settings.perSwipe === '|' && evt.direction === '>' && evt.steps === 0) {
      evt.direction = '|';
      evt.steps = '>';
    }
  });

  // update current slide number
  Events.on('run.after', function () {
    updateCurrentSlide();
  });

  // on resize update total slides and current slide numbers
  Events.on('resize', function () {
    updateTotalSlides();
    updateCurrentSlide();
    updateControls();
  });

  return {
    // on mount set total slides number
    mount () {
      updateTotalSlides();
      updateControls();
    }
  }
}

// parse element data attributes and merge with defaults, in case value starts with { treat it as a json object and try
// to decode it.
function parseDataset(defaults, dataset, prefix) {
  let result = {...defaults}

  for(let key in dataset) {
    if (!key.startsWith(prefix)) {
      continue;
    }

    let variableKey = key.slice(prefix.length);
    variableKey = variableKey.charAt(0).toLowerCase() + variableKey.slice(1)

    result[variableKey] = dataset[key];

    if (result[variableKey].startsWith('{')) {
      result[variableKey] = JSON.parse(result[variableKey]);
    }
  }

  return result;
}

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('.glide')

  if (sliders.length === 0) {
    return;
  }

  sliders.forEach((element) => {
    let options = {
      type: 'carousel',
      startAt: 0,
      perView: 2,
      autoplay: 4000,
      breakpoints: {},
    }

    options = parseDataset(options, element.dataset, 'glide')

    const glide = new Glide(element, options);

    glide.mount({ SlideCounterExtension })
  });
});
